import {
    Marketplace,
    MarketplaceAddress,
    MarketplacePetTypes,
    MarketplaceRange,
    MarketplaceType,
    SpecialHour
} from 'model';
import {
    MarketplaceDto,
    MarketplaceAddressDto,
    MarketplaceRangeDto,
    SpecialHourDto
} from './MarketplaceDtos';

export const convertMarketplace = (marketplaceDto: MarketplaceDto): Marketplace => {
    return {
        address: marketplaceDto.addresses.map(address => convertAddress(address)),
        depositCategory: marketplaceDto.deposit_category,
        depositValue: marketplaceDto.deposit_value,
        id: marketplaceDto.id,
        logo: marketplaceDto.logo_url,
        name: marketplaceDto.business_name,
        phone: marketplaceDto.phone,
        timeZone: marketplaceDto.time_zone,
        slug: marketplaceDto.url_slug,
        active: marketplaceDto.olb_enabled,
        range: convertRange(marketplaceDto.range),
        businessType: convertBusinessType(marketplaceDto),
        specialHours: marketplaceDto.special_hours.map(specialHour =>
            convertSpecialHour(specialHour)
        ),
        fromTimeLimitId: marketplaceDto.olb_minimum_lead_time,
        toTimeLimitId: marketplaceDto.olb_maximum_lead_time,
        allowNewClients: marketplaceDto.olb_accept_new_client,
        allowClientsSelectStaff: marketplaceDto.olb_can_select_staff,
        vaccines: marketplaceDto.pet_requirement_list
            .filter(opt => opt !== 'spay_and_neuter')
            .map(vaccine => ({
                id: vaccine,
                name: vaccine.charAt(0).toUpperCase() + vaccine.slice(1).replace(/[-_]/g, ' ')
            })),
        catsEnabled: convertIsPetTypeEnabled(marketplaceDto.cats_detail),
        dogsEnabled: convertIsPetTypeEnabled(marketplaceDto.dogs_detail),
        exoticsEnabled: convertIsPetTypeEnabled(marketplaceDto.exotics_detail),
        intakeFormEnabled: marketplaceDto.intake_form,
        bookingRequestsEnabled: marketplaceDto.olb_booking_request,
        promoImage: marketplaceDto.promo_image_url,
        petTypes: convertPetTypes(marketplaceDto),
        pricingOption: marketplaceDto.pricing_option
    };
};

const convertSpecialHour = (dto: SpecialHourDto): SpecialHour => {
    const businessHours = () => {
        try {
            return JSON.parse(dto.business_hours);
        } catch (error) {
            return {
                open: '',
                close: ''
            };
        }
    };

    return {
        id: dto.id,
        name: dto.name,
        closed: dto.closed,
        businessHours: businessHours(),
        date: dto.date
    };
};

const convertAddress = (addresses: MarketplaceAddressDto): MarketplaceAddress => {
    return {
        id: addresses.id,
        addressLineOne: addresses.address_line_one,
        addressLineTwo: addresses.address_line_two,
        city: addresses.city,
        country: addresses.country,
        zipcode: addresses.zipcode,
        latitude: addresses.latitude,
        longitude: addresses.longitude,
        state: addresses.state
    };
};

const convertBusinessType = (dto: MarketplaceDto) => {
    if (dto.mobile_business) {
        return MarketplaceType.MOBILE;
    } else if (dto.athome_business) {
        return MarketplaceType.ATHOME;
    } else if (dto.inhome_business) {
        return MarketplaceType.INHOME;
    }

    return MarketplaceType.LOCAL_BUSINESS;
};

const convertRange = (range: MarketplaceRangeDto): MarketplaceRange => {
    return {
        id: range.id,
        active: range.active,
        range: range.range
    };
};

const convertPetTypes = (marketplaceDto: MarketplaceDto): MarketplacePetTypes => {
    const dogsType = marketplaceDto.dogs_detail
        ? JSON.parse(marketplaceDto.dogs_detail)
        : undefined;
    const catsType = marketplaceDto.cats_detail
        ? JSON.parse(marketplaceDto.cats_detail)
        : undefined;
    const exoticsType = marketplaceDto.exotics_detail
        ? JSON.parse(marketplaceDto.exotics_detail)
        : undefined;

    //Retrocompatibility to marketplaces that do not have set the cats sizes.
    if (catsType && (catsType.sizes === undefined || catsType.sizes.length === 0)) {
        catsType.sizes = { id: 'small', name: 'Small', minWeight: null, maxWeight: null };
    }

    return {
        dogs: dogsType
            ? {
                  ages: dogsType.ages || [],
                  sizes: dogsType.sizes || [],
                  hairTypes: dogsType.hairTypes || [],
                  hairLengths: dogsType.hairLengths || []
              }
            : undefined,
        cats: catsType
            ? {
                  ages: catsType.ages || [],
                  sizes: catsType.sizes || [],
                  hairTypes: catsType.hairTypes || [],
                  hairLengths: catsType.hairLengths || []
              }
            : undefined,
        exotics: exoticsType
            ? {
                  types: exoticsType.types || [],
                  others: exoticsType.others || [],
                  sizes: exoticsType.sizes || []
              }
            : undefined
    };
};

const convertIsPetTypeEnabled = (detail: string | undefined): boolean => {
    if ((detail ?? '').length === 0) {
        return false;
    } else {
        const petTypeDetail = JSON.parse(detail ?? '');
        return petTypeDetail.enabled ?? true;
    }
};

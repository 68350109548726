import { Marketplace, MarketplaceType, emptyRange } from 'model';

const marketplaceKey: string = 'marketplaceKey';

export const initMarketplace: Marketplace = {
    businessType: MarketplaceType.LOCAL_BUSINESS,
    range: emptyRange,
    address: [],
    id: undefined,
    slug: '',
    active: false,
    logo: '',
    name: '',
    phone: '',
    specialHours: [],
    fromTimeLimitId: '',
    toTimeLimitId: '',
    allowClientsSelectStaff: false,
    allowNewClients: false,
    vaccines: [],
    petTypes: {},
    pricingOption: 'starting_at'
};

export const cleanMarketplaceLocalStorage = () => {
    localStorage.removeItem(marketplaceKey);
};

export const setMarketplaceLocalStorage = (marketplace: Marketplace) => {
    localStorage.setItem(marketplaceKey, JSON.stringify(marketplace));
};

export const getMarketplaceFromLocalStorage = (): Marketplace => {
    try {
        const stringifyMarketplace = localStorage.getItem(marketplaceKey);

        if (stringifyMarketplace) {
            return JSON.parse(stringifyMarketplace);
        } else {
            return { ...initMarketplace };
        }
    } catch (error) {
        cleanMarketplaceLocalStorage();
        return { ...initMarketplace };
    }
};

import React, { useMemo } from 'react';
import MarketplaceInfoReverse from '../MarketplaceInfoReverse/MarketplaceInfoReverse';
import { getBreedName } from 'utils';
import { EditBooking } from 'model/Booking';
import { BlackHeading } from 'components';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import { Marketplace } from 'model';
import { RootState } from 'store';
import { PaymentsState } from '@spike/payments-action';

import '../utils/BookingResume.scss';
import PetIcon from 'components/Pet/PetIcon/PetIcon';

interface BookingResumeProps {
    booking: EditBooking;
    loading?: boolean;
    marketplace: Marketplace;
    showService?: boolean;
    showBilling?: boolean;
    showDate?: boolean;
    showPet?: boolean;
    variableDuration?: [];
    //
    onCompletePayment?: () => void;
    onSubmit?: () => void;
}

const BookingResume: React.FunctionComponent<BookingResumeProps> = props => {
    const bookingState = props.booking;
    const paymentState = useSelector<RootState, PaymentsState | undefined>(state => state.payments);

    const deposit = useMemo(() => {
        const depositAmount = paymentState?.dropIn?.amount.value;

        return depositAmount ? depositAmount / 100 : 0;
    }, [paymentState?.dropIn]);

    const subtotal: number = useMemo(() => {
        return bookingState.petsToBooking.reduce((subtotal, booking) => {
            const servicePrice = Number(booking.service?.price);

            if (!isNaN(servicePrice)) {
                subtotal += servicePrice;
            }

            return subtotal;
        }, 0);
    }, [bookingState.petsToBooking]);

    const totalAppointmentDay = useMemo(() => {
        return subtotal - deposit;
    }, [deposit, subtotal]);

    const serviceDuration = useMemo(() => {
        let hours = '';
        let minutes = '';

        const service = bookingState.petsToBooking[0]?.service;

        if (!service) {
            return '';
        }

        if (service?.durationHours || service?.durationMinutes) {
            hours = String(service?.durationHours);
            minutes = String(service?.durationMinutes);
        } else if (service.variableDuration.length > 0) {
            const variableDuration = service.variableDuration.find(
                variableDuration =>
                    variableDuration.petSize.id === bookingState.petsToBooking[0].pet.size
            );

            hours = String(variableDuration?.duration.hours);
            minutes = String(variableDuration?.duration.minutes);
        }

        hours = Number(hours) > 1 ? `${hours} Hours` : Number(hours) === 0 ? '' : `${hours} Hour`;
        minutes = Number(minutes) > 0 ? `${minutes} Minutes` : '';

        return `${hours} ${minutes}`;
    }, [bookingState.petsToBooking]);

    const depositView = (
        <>
            <Box className="BookingResume-billing-deposit BookingResume-billing-total">
                <span>Deposit to book</span>
                <span>${deposit}</span>
            </Box>

            <Box className="BookingResume-billing-totalApp">
                <span>Total on your appointment day</span>
                <span>${totalAppointmentDay}</span>
            </Box>
        </>
    );

    return (
        <>
            <Box className="BookingResume">
                <MarketplaceInfoReverse marketplace={props.marketplace} sx={{ mb: 2 }} />

                <Box className="BookingResume-heading">
                    <BlackHeading title="Booking details" divider={false} />

                    {bookingState.date && (
                        <Box className="BookingResume-date">
                            {bookingState.date && bookingState.date.object.toFormat('dd MMMM y')}
                        </Box>
                    )}
                </Box>

                <Box>
                    <Box className="BookingResume-content">
                        {props.showPet &&
                            bookingState.petsToBooking &&
                            bookingState.petsToBooking
                                .sort((a, b) => (a.pet.id! > b.pet.id! ? 1 : -1))
                                .map((item, key) => (
                                    <Box
                                        className={`BookingResume-pet BookingResume-${item.pet.type.id}`}
                                        key={key}
                                    >
                                        <Box className="BookingResume-image">
                                            {item.pet.image ? (
                                                <img src={item.pet.image} alt="pet" />
                                            ) : (
                                                <PetIcon
                                                    size="100%"
                                                    pet={item.pet}
                                                    variant="light"
                                                />
                                            )}
                                        </Box>
                                        <Box className="BookingResume-info">
                                            <p className="BookingResume-info-name">
                                                {item.pet.name}
                                                <span
                                                    className={`BookingResume-badge BookingResume-badge-${item.pet.type.id}`}
                                                >
                                                    {item.pet.type.singular}
                                                </span>
                                            </p>
                                            <p className="BookingResume-info-description">
                                                {getBreedName(item.pet.type.id, item.pet.breed)} -{' '}
                                                {capitalize(item.pet.size)}
                                            </p>
                                            {props.showService && item.service && (
                                                <p className="BookingResume-info-description">
                                                    <span className="BookingResume-info-label">
                                                        Service
                                                    </span>{' '}
                                                    <b>{item.service.name}</b>
                                                </p>
                                            )}
                                            {props.showService &&
                                                serviceDuration &&
                                                serviceDuration.length > 0 && (
                                                    <p className="BookingResume-info-description">
                                                        <span className="BookingResume-info-label">
                                                            Duration
                                                        </span>{' '}
                                                        <b>{serviceDuration}</b>
                                                    </p>
                                                )}
                                            {props.showDate && item.hour && (
                                                <p className="BookingResume-info-description">
                                                    <span className="BookingResume-info-label">
                                                        Time
                                                    </span>{' '}
                                                    <b>{item.hour.hour}</b>
                                                </p>
                                            )}
                                        </Box>

                                        {props.showService && item.service && (
                                            <Box className="BookingResume-info-price">
                                                $<b>{item.service.price}</b>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                    </Box>
                    <Box className="BookingResume-billing">
                        {subtotal > 0 && (
                            <Box className="BookingResume-billing-item">
                                <span>
                                    {props.marketplace.pricingOption === 'starting_at'
                                        ? 'Starting at'
                                        : 'Price'}
                                </span>
                                <span id="bookingResume_label_subTotal">
                                    ${subtotal.toString()}
                                </span>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            {paymentState?.dropIn && <Box>{depositView}</Box>}
        </>
    );
};

export default BookingResume;
